import React, { Fragment } from "react"
import { StringUtils } from "../utils"

export default ({ stats }) => (
  <div>
    {Object.entries(stats)
      .map(([name, value]) => {
        return `${name === "dur" ? "Durability" : StringUtils.SentenceCase(name)}: ${value}`
      })
      .map(x => (
        <Fragment key={x}>
          {x}
          <br />
        </Fragment>
      ))}
  </div>
)
