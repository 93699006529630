import React from "react"
import styled from "@emotion/styled"
import ToolIcon from "./ToolIcon"
import StatsTooltip from "./StatsTooltip"

export default ({ items, selectedItem, onClick, type, test, showToolTips }) => {
  return (
    <FlexWrapDiv>
      {items.map((item, index) => (
        <ToolIcon
          key={item.name}
          name={item.name}
          stats={showToolTips && <StatsTooltip stats={item.toolStats[type]} />}
          isSelected={selectedItem === item.name}
          onClick={e => onClick(e)}
          highlight={test(item)}
        />
      ))}
    </FlexWrapDiv>
  )
}
const FlexWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px;
  align-content: center;
  justify-content: center;
`
