import React, { useState } from "react"
import styled from "@emotion/styled"
import FilterableList from "./FilterableList"
import FilterStats from "./FilterStats"

const miningLevelMap = { stone: 1, iron: 2, diamond: 3, obsidian: 4, cobalt: 5 }
export default ({ materials, selectedMaterial, part, onPartFinished, type, selectedTraits, showToolTips }) => {
  const [sortBy, setSortBy] = useState()
  const [material, setMaterial] = useState(selectedMaterial || {})
  const representativeMaterial = materials.find(material => material.toolStats[type])
  const filterableStats = representativeMaterial && Object.keys(representativeMaterial.toolStats[type])
  const validMaterialGivenPart = material => {
    if (!material) return material
    const partCanBeMadeWithMaterial = material.toolStats[type]
    return partCanBeMadeWithMaterial ? material : false
  }
  const validMaterialGivenTraits = material => {
    // return material
    if (!material.traits || !selectedTraits.length) return material

    const materialHasTrait = [...(material.traits[type] || []), ...(material.traits.any || [])].some(trait =>
      selectedTraits.includes(trait)
    )
    return materialHasTrait ? material : false
  }
  const validMaterials = materials.filter(x => !!validMaterialGivenPart(x))
  const mapIfHarvest = (sortBy, x) => (sortBy.toLowerCase() === "harvestlevel" ? miningLevelMap[x.toLowerCase()] : x)

  const sortedMaterials = sortBy
    ? validMaterials.sort((a, b) => {
        const bVal = mapIfHarvest(sortBy, b.toolStats[type][sortBy])
        const aVal = mapIfHarvest(sortBy, a.toolStats[type][sortBy])
        return aVal === bVal ? 0 : bVal - aVal
      })
    : validMaterials
  return (
    <>
      <FlexDiv>
        <FilterStats partKey={part.key} filterableStats={filterableStats} onChange={e => setSortBy(e.target.value)} />
        <FilterableList
          items={sortedMaterials}
          type={type}
          selectedItem={material.name}
          showToolTips={showToolTips}
          onClick={e => {
            const material = materials.find(material => material.name === e.target.id)
            onPartFinished({
              ...material.toolStats[type],
              type,
              traits: material.traits[type] || material.traits.any || [],
              materialName: material.name,
              partName: part.name,
            })
            setMaterial(material)
          }}
          test={material => validMaterialGivenTraits(material)}
        />
      </FlexDiv>
    </>
  )
}
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: column;
`
