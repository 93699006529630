export const traitDescriptions = {
  alien: "Gain random amounts of durability, speed, and damage over time.",
  aquadynamic: "Operates at normal speed underwater and operates faster when raining.",
  aridiculous: "Mining speed increases based on biome temperature.",
  autosmelt: "If the broken block is smeltable, it will drop that item (logs drop charcoal).",
  beheading: "Much higher chance of mobs dropping a head.",
  cheap: "10% bonus durability when repairing.",
  cheapskate: "Reduces tool durability by 20%",
  coldblooded: "Adds 50% additional damage if target has full health.",
  crude: "10% bonus damage to unarmored enemies.",
  crumbling: "If the material does not require a tool, multiplies speed by speed*0.5.",
  dense: "Chance to use less durability. Increased chance as the tool gets more damaged.",
  duritae: "10% chance to use double durability, 40% chance to use no durability.",
  ecological:
    "1% chance every second to restore 1 durability. 1% chance on use to deal armor-piercing damage to the wearer.",
  enderference: "Prevent an enderman from teleporting for 5 seconds after hitting it.",
  evilaura: "the evil entities whisper to you",
  fractured: "Bonus 1.5 damage.",
  flammable: "Sets attackers on fire while blocking.",
  hellish: "Adds +4 damage (2 heart) against non-nether entities.",
  insatiable:
    "Using the tool adds a level of insatiable for 15 seconds, limited to 10 levels. Increases speed and damage by (level / 3).",
  jagged:
    "Gains bonus damage when tool is damaged. The more damaged it is (difference, not percentage), the more damage it deals.",
  lightweight: "Bonus 10% mining speed, attack speed, bow draw speed.",
  magnetic: "Pulls in items from ~2 blocks away when used.",
  momentum: "When tool is used, increases tool's mining speed up to 40%. Momentum is lost when tool stops being used.",
  petramor: "10% chance to consume mined stone-type blocks to restore 5 durability.",
  poisonous: "Applies poison for 5 seconds on damage.",
  prickly: "Deals armor-piercing damage to enemies hit and to player when mining.",
  sharp: "Applies bleeding for 6 seconds.",
  shocking: "Builds electric charge as you move. Deals 5 bonus damage (2.5 hearts) when fully charged.",
  slimey:
    "0.3% chance to summon a small slime corresponding to the color of the tool part. (Tools made of both blue and green slime have separate chances to spawn blue and green slimes.)",
  stiff: "When blocking, reduce damage by 1 damage (0.5 hearts). Will not reduce damage to below 1.",
  superheat: "35% bonus damage to burning enemies.",
  spiky: "Enemies that touch you while blocking take damage.",
  splintering: "Attacks apply a level of Splintering, up to 5 levels. Deal +0.3 bonus damage per level of Splintering.",
  squeaky: "Tool has silk touch but doesn't do damage.",
  stonebound: "Mines faster and deals less damage as durability lowers.",
  tasty:
    "If player has less than half hunger, 0.2% chance each tick to consume your tool, removing 100 durability and restoring 1 bar of hunger. When breaking blocks, 0.5% chance to get bacon. When killing mobs, 5% chance to get bacon.",
  unnatural: "Speed is increased by 1 for every harvest level the tool is above the material being broken.",
  established: "25% bonus experience. 3% chance for 1 XP from blocks that normally give none.",
  writable: "Bonus 1 modifier. If the whole tool is made of paper, you gain 2 modifiers.",
}
