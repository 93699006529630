import styled from "@emotion/styled"

export const Button = styled.button`
  border: 0;
  border-radius: 10px;
  color: white;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #de530e;
`
