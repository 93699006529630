import React from "react"
import PartTabs from "./PartTabs"
import PartMaker from "./PartMaker"

export default ({
  partTabs,
  partIds,
  setActiveTab,
  toolsImgs,
  activeTab,
  materials,
  material,
  part,
  currentTool,
  selectedTraits,
  ingredientToDisplay,
  showToolTips,
  onPartFinished,
}) => (
  <PartTabs tabs={partTabs} ids={partIds} onClick={setActiveTab} imgs={toolsImgs} activeTabId={activeTab}>
    <PartMaker
      key={ingredientToDisplay.key}
      materials={materials}
      selectedMaterial={material}
      selectedTool={currentTool}
      part={{ ...part, key: ingredientToDisplay.key }}
      selectedTraits={selectedTraits}
      type={ingredientToDisplay.type}
      showToolTips={showToolTips}
      onPartFinished={info => onPartFinished({ ...info, key: ingredientToDisplay.key })}
    />
  </PartTabs>
)
