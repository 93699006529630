import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ToolTinkerer from "../components/ToolTinkerer"

const IndexPage = ({ pageContext }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <ToolTinkerer {...pageContext} />
    </Layout>
  )
}

export default IndexPage
