import React from "react"
import styled from "@emotion/styled"

import * as toolsImgs from "../images/parts"
export default ({ tabs, ids, onClick, children, activeTabId }) => {
  return (
    <TabContentContainer>
      <TabContainer>
        {tabs.map((tab, index) => (
          <PhotoBackground
            key={ids[index]}
            id={ids[index]}
            onClick={e => onClick(e.target.id)}
            active={activeTabId === ids[index]}
          >
            <StyledImg id={ids[index]} src={toolsImgs[tab.toLowerCase().replace(/ /g, "_")]} />
            {tab}
          </PhotoBackground>
        ))}
      </TabContainer>
      {children}
    </TabContentContainer>
  )
}

const StyledImg = styled.img`
  width: 32px;
`
const PhotoBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #8b8b8b;
  border-top: 2px ${p => (p.active ? "black" : "white")} solid;
  border-left: 2px ${p => (p.active ? "black" : "white")} solid;
  border-bottom: 2px ${p => (!p.active ? "black" : "white")} solid;
  border-right: 2px ${p => (!p.active ? "black" : "white")} solid;
  padding: 8px;
  margin: 0px 1px;
`
const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  background: #c6c6c6;
  padding: 8px;
`
