import React from "react"
import styled from "@emotion/styled"
import TinkerBorder from "./TinkerBorder"
export default ({ partKey, filterableStats = [], onChange }) => {
  return (
    <FlexDiv>
      {filterableStats.map(stat => (
        <FullLengthLabel htmlFor={partKey + stat} key={stat}>
          <input type="radio" id={partKey + stat} name={partKey} value={stat} onChange={onChange} />
          {stat}
        </FullLengthLabel>
      ))}
    </FlexDiv>
  )
}

const FlexDiv = styled(TinkerBorder)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`
const FullLengthLabel = styled.label`
  white-space: nowrap;
  cursor: pointer;
`
