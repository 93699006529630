import React, { useState } from "react"
import styled from "@emotion/styled"

export default ({ onToggle, text }) => {
  const [active, setActive] = useState(true)

  return (
    <ToggleButton
      onClick={() => {
        onToggle && onToggle(!active)
        setActive(!active)
      }}
      isActive={active}
    >
      <ToggleSwitch isActive={active}></ToggleSwitch>
      <ToggleText isActive={active}>{text}</ToggleText>
    </ToggleButton>
  )
}

const ToggleText = styled.span`
  position: relative;
  transition: all 1s;
  left: ${p => (p.isActive ? "-40px" : "-10px")};
`
const ToggleSwitch = styled.div`
  width: 30px;
  background: white;
  position: relative;
  height: 30px;
  display: inline-block;
  border-radius: 40px;
  margin: 0px 8px;
  transition: all 1s;
  left: ${p => (p.isActive ? "70px" : "-10px")};
`
const ToggleButton = styled.button`
  border: 0;
  border-radius: 10px;
  color: white;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;

  background: ${p => (p.isActive ? "#de530e" : "gray")};
`
