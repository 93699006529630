import React, { useState, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import Tool from "./Tool"
import Traits from "./Traits"

import ToolMaker from "./ToolMaker"
import ToggleButton from "./ToggleButton"
import ShareableUrl from "./ShareableUrl"
import TinkerBorder from "./TinkerBorder"

export default ({ tools, materials, parts }) => {
  //hack for telling effect and linter that these props are constant
  const effectTools = useRef(tools)
  const effectMaterials = useRef(materials)

  const [currentTool, setCurrentTool] = useState(tools.find(tool => tool.name === "Pickaxe"))
  const [activeTab, setActiveTab] = useState(currentTool.recipe[0].key)
  const [selectedParts, setSelectedParts] = useState({})
  const [showToolTips, setShowToolTips] = useState(true)
  const [selectedTraits, setSelectedTraits] = useState([])

  useEffect(() => {
    if (typeof window !== undefined && window.location.hash) {
      const decodedState = decodeURI(window.location.hash).slice(1)
      const hashedState = window.location.hash && JSON.parse(window.atob(decodedState))
      console.log("TCL: hashedState", hashedState)
      const tool = effectTools.current.find(x => x.name === hashedState.tool)
      const selectedParts = hashedState.parts
        .map(part => {
          const material = effectMaterials.current.find(material => material.name === part.materialName)
          const ingredient = tool.recipe.find(x => x.key === part.key)
          const type = ingredient.type
          return {
            ...part,
            traits: material.traits[type] || material.traits.any,
            ...material.toolStats[type],
            partName: ingredient.part,
            type,
          }
        })
        .reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {})

      setCurrentTool(tool)
      setActiveTab(tool.recipe[0].key)
      setSelectedParts(selectedParts)
    }
  }, [])
  const partTabs = currentTool.recipe && currentTool.recipe.map(x => x.part)
  const partIds = currentTool.recipe && currentTool.recipe.map(x => x.key)
  const ingredientToDisplay = currentTool.recipe && currentTool.recipe.find(ingredient => activeTab === ingredient.key)

  return (
    <>
      <SelectionDiv>
        <FlexWrapDiv>
          Select a Tool
          <MarginSelect
            value={currentTool.name}
            onChange={e => {
              const newTool = tools.find(x => x.name.toLowerCase() === e.target.value.toLowerCase())
              setSelectedParts({})
              setCurrentTool(newTool)
              setActiveTab(newTool.recipe[0].key)
            }}
          >
            {tools.map((tool, index) => (
              <option key={index} value={tool.name}>
                {tool.name}
              </option>
            ))}
          </MarginSelect>
        </FlexWrapDiv>
        <ToggleButton text={"ToolTips"} onToggle={setShowToolTips} />
      </SelectionDiv>
      <ShareableUrl tool={currentTool} parts={selectedParts} />
      {ingredientToDisplay && (
        <ToolMaker
          partTabs={partTabs}
          partIds={partIds}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          materials={materials}
          material={materials.find(
            material => material.name === (selectedParts[ingredientToDisplay.key] || {}).materialName
          )}
          currentTool={currentTool}
          selectedTraits={selectedTraits}
          part={parts.find(part => part.name === ingredientToDisplay.part)}
          ingredientToDisplay={ingredientToDisplay}
          onPartFinished={part => setSelectedParts({ ...selectedParts, [part.key]: part })}
          showToolTips={showToolTips}
        />
      )}
      {currentTool.recipe && (
        <Tool parts={selectedParts} modifiers={currentTool.modifiers} showToolTips={showToolTips} />
      )}
      <Traits
        materials={materials}
        showToolTips={showToolTips}
        onClick={trait => {
          console.log("TCL: trait", trait)
          if (!trait) {
            setSelectedTraits([])
            return
          }
          if (selectedTraits.includes(trait)) {
            setSelectedTraits(selectedTraits.filter(x => x !== trait))
          } else setSelectedTraits([...selectedTraits, trait])
        }}
        type={ingredientToDisplay && ingredientToDisplay.type}
        selectedTraits={selectedTraits}
      />
    </>
  )
}

const MarginSelect = styled.select`
  margin-left: 8px;
  padding: 4px;
`

const SelectionDiv = styled(TinkerBorder)`
  display: flex;
  margin: 8px;
  justify-content: center;
  align-items: center;
`
const FlexWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px;
  padding: 8px;
  width: 320px;
  justify-content: center;
  align-items: center;
`
