import React from "react"
import styled from "@emotion/styled"
import Tooltip from "react-tooltip-lite"
import { StringUtils } from "../utils"

export default ({ name, isSelected, icon, onClick, highlight = false, stats }) => {
  return stats ? (
    <Tooltip
      content={<div style={{ overflowWrap: "break-word", color: "black" }}>{<div>{stats}</div>}</div>}
      background={"lightgray"}
    >
      <ToolIcon onClick={onClick} id={name} highlight={highlight} isSelected={isSelected}>
        {StringUtils.SentenceCase(name)}
      </ToolIcon>
    </Tooltip>
  ) : (
    <ToolIcon onClick={onClick} id={name} highlight={highlight} isSelected={isSelected}>
      {StringUtils.SentenceCase(name)}
    </ToolIcon>
  )
}

const ToolIcon = styled.button`
  padding: 6px;
  margin: 6px;
  width: 100px;
  @media (min-width: 350px) {
    padding: 8px;
    margin: 8px;
  }
  border-radius: 5px;
  cursor: pointer;
  color: black;

  background: white;
  ${p => !p.highlight && "background: gray;"}
  ${p =>
    p.isSelected &&
    `
  color: white;
  background: #de530e;
    `};
`
