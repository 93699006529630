import React from "react"
import styled from "@emotion/styled"
import { StringUtils } from "../utils"
import TinkerBorder from "./TinkerBorder"
import { traitDescriptions } from "./constants"
import Tooltip from "react-tooltip-lite"

const miningLevelMap = { stone: 1, iron: 2, diamond: 3, obsidian: 4, cobalt: 5 }
export default ({ parts, modifiers, showToolTips }) => {
  const averagePartTypeStat = (partType, stat) => {
    const validParts = Object.values(parts).filter(part => part.type.toLowerCase() === partType)
    if (!validParts.length) return 0
    return validParts.reduce((acc, cur) => acc + cur[stat], 0) / validParts.length
  }

  //normal
  const miningLevel = Math.max(
    ...Object.values(parts)
      .reduce((acc, cur) => (cur.harvestLevel ? [...acc, cur.harvestLevel] : acc), [])
      .map(x => x && miningLevelMap[x.toLowerCase()])
  )

  const harvestLevel = Object.keys(miningLevelMap).find(x => miningLevelMap[x] === miningLevel)

  const headDurability = averagePartTypeStat("head", "dur")
  const headSpeed = Math.round(averagePartTypeStat("head", "speed") * modifiers.speed)
  const headAttack = Math.round(averagePartTypeStat("head", "attack") + 1)
  const extraDurability = averagePartTypeStat("extra", "dur")
  const handleDurability = averagePartTypeStat("handle", "dur")

  const handleModifier = averagePartTypeStat("handle", "modifier")

  const totalDurability = Math.round(
    Math.max(1, (headDurability + extraDurability) * (handleModifier || 1) + handleDurability) * modifiers.dur
  )
  const normalStats = [
    { value: totalDurability, name: "Durability" },
    { value: headSpeed, name: "Speed" },
    { value: headAttack, name: "Attack" },
    { value: harvestLevel, name: "HarvestLevel" },
  ]
  //Arrow/bolt
  const arrowShaftModifier = averagePartTypeStat("arrowshaft", "modifier")
  const arrowShaftDurability = averagePartTypeStat("arrowshaft", "ammo")

  const fletchingModifier = averagePartTypeStat("fletching", "modifier")
  const fletchingAccuracy = averagePartTypeStat("fletching", "accuracy")

  const arrowAmmo = Math.round(
    Math.round(
      Math.round(Math.round(headDurability * fletchingModifier) * arrowShaftModifier) + arrowShaftDurability * 10
    ) / 10
  )
  const arrowStats = [
    { value: arrowAmmo, name: "Ammo" },
    { value: fletchingAccuracy, name: "Accuracy" },
    { value: headAttack, name: "Attack" },
  ]
  //bow/crossbow/etc
  const bowstringModifier = averagePartTypeStat("bowstring", "modifier")

  const bowSpeed = averagePartTypeStat("bow", "drawSpeed") * bowstringModifier
  const bowRange = averagePartTypeStat("bow", "range") * bowstringModifier
  const bowDamage = averagePartTypeStat("bow", "bonusDamage") * bowstringModifier
  const bowAttack = averagePartTypeStat("bow", "attack") + bowDamage
  const bowDurability = averagePartTypeStat("bow", "dur") + extraDurability
  const bowStats = [
    [
      { value: bowAttack, name: "Attack" },
      { value: bowSpeed, name: "Speed" },
      { value: bowDurability, name: "Durability" },
      { value: bowRange, name: "Range" },
    ],
  ]

  const toolStats = fletchingModifier ? arrowStats : bowDamage ? bowStats : normalStats
  return (
    <PartContainer>
      <PartBackground empty={!Object.values(parts).length}>
        <FlexColumnContainer>
          Parts:
          {Object.values(parts).map(part => (
            <StatsDiv key={`${part.key}`}>
              {StringUtils.SentenceCase(part.materialName)} {part.partName}
            </StatsDiv>
          ))}
        </FlexColumnContainer>
      </PartBackground>
      <PartBackground empty={!Object.values(parts).length}>
        <FlexColumnContainer>
          Raw Stats:
          {!!Object.values(parts).length &&
            toolStats.map(stat => (
              <StatsDiv key={stat.name}>
                {stat.name}: {stat.value}
              </StatsDiv>
            ))}
        </FlexColumnContainer>
      </PartBackground>
      <PartBackground empty={!Object.values(parts).length}>
        <FlexColumnContainer>
          Traits:{" "}
          {[...new Set(Object.values(parts).reduce((acc, cur) => [...acc, ...cur.traits], []))].map(x =>
            showToolTips ? (
              <Tooltip
                key={x}
                content={
                  <div style={{ overflowWrap: "break-word", color: "black" }}>
                    {<div>{traitDescriptions[x.replace(/ .*/, "")]}</div>}
                  </div>
                }
                background={"lightgray"}
              >
                <StatsDiv>{x}</StatsDiv>
              </Tooltip>
            ) : (
              <StatsDiv>{x}</StatsDiv>
            )
          )}
        </FlexColumnContainer>
      </PartBackground>
    </PartContainer>
  )
}
const PartContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StatsDiv = styled.div`
  padding: 4px 0px;
  margin-left: 24px;
`
const PartBackground = styled(TinkerBorder)`
  box-sizing: border-box;
  width: 100%;
  @media (min-width: 900px) {
    width: 30%;
  }
`
