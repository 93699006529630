import React from "react"
import styled from "@emotion/styled"
import ToolIcon from "./ToolIcon"
import { traitDescriptions } from "./constants"
import { MaterialUtils } from "../utils"
import TinkerBorder from "./TinkerBorder"
import { Button } from "./SharedStyledDom"
export default ({ materials, selectedTraits, type, onClick, showToolTips }) => {
  const validTraits = [
    ...new Set(
      materials
        .filter(material => material.toolStats[type])
        .reduce((acc, cur) => [...acc, ...(MaterialUtils.getTypeTraitsOrAny(cur, type) || [])], [])
    ),
  ]

  return (
    <>
      <FullWidthDiv>
        <TinkerBorder>Filter By Traits</TinkerBorder>
        <Button onClick={() => onClick()}>Clear Traits</Button>
      </FullWidthDiv>
      <FlexWrapDiv>
        {validTraits.map(trait => (
          <ToolIcon
            key={trait}
            name={trait}
            stats={showToolTips && traitDescriptions[trait.replace(/ .*/, "")]}
            isSelected={selectedTraits.includes(trait)}
            onClick={e => onClick(e.target.id)}
            highlight={true}
          />
        ))}
      </FlexWrapDiv>
    </>
  )
}
const FullWidthDiv = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
`

const FlexWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  align-items: center;
`
