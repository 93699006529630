import React, { useState } from "react"
import styled from "@emotion/styled"
import { Button } from "./SharedStyledDom"

export default ({ parts, tool }) => {
  const [copy, setCopy] = useState(false)
  const generateUrlFromState = () => {
    if (typeof window !== undefined) {
      const minimalParts = Object.values(parts).map(({ materialName, key }) => ({
        materialName,
        key,
      }))
      window.location.hash = encodeURI(
        window.btoa(
          JSON.stringify({
            tool: tool.name,
            parts: minimalParts,
          })
        )
      )
      navigator.clipboard.writeText(window.location.href)
      setCopy(true)
      setTimeout(() => setCopy(false), 3000)
    }
  }
  return (
    <FullWidthDiv>
      <Button onClick={() => generateUrlFromState()}>Create Shareable Url</Button>
      <AnimateDiv show={copy}>Copied</AnimateDiv>
    </FullWidthDiv>
  )
}
const AnimateDiv = styled.div`
  transition: all 500ms;
  opacity: ${p => (p.show ? "1" : "0")};
`
const FullWidthDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
  width: 100%;
`
